import { useTheme } from 'next-themes';
import { FC } from 'react';
import { circuitBoardPattern } from '../../../assets/patterns/circuit-board';
import { LegalFooterSection } from '../../pages/shared/legal-footer-section';
import { SocialMediaSection } from '../../pages/shared/social-media-section';
import { BackgroundPatternWrapper } from '../../ui/background-pattern-wrapper';

export const FooterSection: FC = () => {
	const isDark = useTheme().resolvedTheme === 'dark';

	return (
		<BackgroundPatternWrapper
			pattern={circuitBoardPattern(isDark ? '#ffffff' : '#000000')}
			className='bg-main-bg dark:bg-main-bg-dark'
			patternClassName='opacity-5'
		>
			<SocialMediaSection />
			<LegalFooterSection />
		</BackgroundPatternWrapper>
	);
};
