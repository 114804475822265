import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import { Project } from '../../../../types/collections/project';
import { strapiImageUrl } from '../../../../util/strapi';
import BorderEffectGrid from '../../../ui/border-effect-grid';
import { ProjectGridItemOverlay } from '../../shared/project-grid-item/overlay';

const classNames = [
	clsx(
		'col-span-1 row-span-1 aspect-w-3 aspect-h-2', // <sm
		'sm:col-span-2 sm:row-span-2 sm:aspect-1', // sm
		'lg:col-span-2 lg:row-span-3 lg:aspect-[0] lg:aspect-w-2 lg:aspect-h-3' // lg
	),
	clsx(
		'col-span-1 row-span-1 aspect-w-3 aspect-h-2', // <sm
		' ', // sm
		'lg:col-span-2 lg:row-span-1 lg:aspect-w-2 lg:aspect-h-1' // lg
	),
	clsx(
		'col-span-1 row-span-1 aspect-w-3 aspect-h-2', // <sm
		'', // sm
		'lg:aspect-1' // lg
	),
	clsx(
		'col-span-1 row-span-1 aspect-w-3 aspect-h-2', // <sm
		'sm:col-span-3 sm:aspect-w-3 sm:aspect-h-1', // sm
		'lg:col-span-1 lg:aspect-1' // lg
	),
	clsx(
		'col-span-1 row-span-1 aspect-w-3 aspect-h-2', // <sm
		' sm:aspect-w-3 sm:aspect-1', // sm
		'lg:col-span-2 lg:row-span-2 lg:aspect-1' // lg
	),
	clsx(
		'col-span-1 row-span-1 aspect-w-3 aspect-h-2', // <sm
		'sm:col-span-2 sm:aspect-h-1', // sm
		'lg:aspect-w-2 lg:aspect-0' // lg
	),
];

type Props = {
	projects: Project[];
};
export const FeaturedProjectsGrid: FC<Props> = ({ projects }) => {
	const featuredProjects = projects.slice(0, 6);

	return (
		<BorderEffectGrid
			className={clsx(
				'grid grid-flow-row-dense transition-all duration-1000',
				'grid-cols-2 gap-4', // <sm
				'sm:grid-cols-3 sm:gap-6', // sm
				'lg:grid-cols-4 lg:gap-8' // lg
			)}
		>
			{featuredProjects.map((project, i) => (
				<BorderEffectGrid.Item key={`project_${project.id}`} cardClassName={classNames[i]}>
					<Link
						href={`/projects/${project.slug}`}
						className={clsx(
							'group',
							'relative',
							'rounded-xl sm:rounded-2xl lg:rounded-3xl',
							'shadow-lg',
							'cursor-pointer',
							'overflow-hidden',
							'w-full h-full'
						)}
					>
						<div className='scale-100 group-hover:scale-105 transition-all duration-200 w-full h-full'>
							<Image
								src={strapiImageUrl(project.featuredImage)}
								alt={project.title}
								className={clsx('object-cover', 'rounded-xl sm:rounded-2xl lg:rounded-3xl')}
								fill
								sizes='800px'
							/>
						</div>
						<ProjectGridItemOverlay text={project.title} />
					</Link>
				</BorderEffectGrid.Item>
			))}
		</BorderEffectGrid>
	);
};
