import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';
import { Container } from '../../../layout/container';
import { DividerBottom } from './divider-bottom';
import { DividerTop } from './divider-top';
import { FactItem } from './fact-item';
import { GitStatsDialog } from './git-stats-dialog';

export type FactsProps = {
	coursesCount: number;
	coursesDuration: number;
	projectsCount: number;
	commitsCount: number;
	linesAdded: number;
	linesDeleted: number;
};
export const FactsSection: FC<FactsProps> = (props) => {
	const [gitStatsDialogOpen, setGitStatsDialogOpen] = useState<boolean>(false);

	const { t } = useTranslation('index');
	return (
		<>
			<div className='relative'>
				<DividerTop />
				<DividerBottom />

				<div className='bg-gradient-to-r from-primary dark:from-primary-dark to-secondary dark:to-secondary-dark'>
					<Container className='py-12 md:py-24'>
						<div className='facts'>
							<FactItem
								value={props.coursesCount}
								caption={t('facts.facts.courses')}
								href='/resume'
							/>
							<FactItem
								value={props.coursesDuration}
								suffix='+ h'
								caption={t('facts.facts.learning')}
								href='/resume'
							/>
							<FactItem
								value={props.projectsCount}
								caption={t('facts.facts.projects')}
								href='/projects'
							/>
							<FactItem
								value={props.commitsCount}
								caption={t('facts.facts.commits')}
								onClick={() => setGitStatsDialogOpen(true)}
							/>
						</div>
					</Container>
				</div>
			</div>

			<GitStatsDialog
				open={gitStatsDialogOpen}
				onClose={() => setGitStatsDialogOpen(false)}
				data={props}
			/>
		</>
	);
};
