import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import classes from './index.module.scss';

type Props = {
	className?: string;
	cardClassName?: string;
};
export const BorderEffectGridItem: FC<PropsWithChildren<Props>> = (props) => {
	return (
		<div className={clsx('card', classes.card, props.cardClassName)}>
			<div className={classes.cardContentWrapper}>
				<div className={clsx(classes.cardContent)}>{props.children}</div>
			</div>
		</div>
	);
};
