import { GetStaticProps, NextPage } from 'next';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { FooterSection } from '../components/layout/footer';
import { LayoutWrapper } from '../components/layout/layout-wrapper';
import { FactsProps, FactsSection } from '../components/pages/home/facts';
import { HeroSection } from '../components/pages/home/hero';
import { PortfolioSection } from '../components/pages/home/portfolio';
import { ServicesSection } from '../components/pages/home/services';
import { TestimonialsSection } from '../components/pages/home/testimonials';
import { ContactSection } from '../components/pages/shared/contact-section';
import { Seo } from '../components/util/seo';
import { getCoursesCompleteInfo } from '../data/courses';
import { getFeaturedProjects } from '../data/projects';
import { getTestimonials } from '../data/testimonials';
import { http } from '../services/http';
import { Project } from '../types/collections/project';
import { Testimonial } from '../types/collections/testimonial';
import { calculateAge } from '../util';
import { getTranslations } from '../util/i18n';

type Props = {
	testimonials: Testimonial[];
	featuredProjects: Project[];
};

export const getStaticProps: GetStaticProps<Props> = async ({ locale }) => {
	const testimonials = await getTestimonials({ locale, populate: '*', sort: 'createdAt:DESC' });
	const featuredProjects = await getFeaturedProjects(locale);

	return {
		props: {
			...(await getTranslations(locale, 'index')),
			testimonials,
			featuredProjects,
		},
	};
};

const HomePage: NextPage<Props> = (props) => {
	//#region data
	const [coursesCount, setCoursesCount] = useState<number>(0);
	const [coursesDuration, setCoursesDuration] = useState<number>(0);
	const [projectsCount, setProjectsCount] = useState<number>(0);
	const [commitsCount, setCommitsCount] = useState<number>(0);
	const [linesAdded, setLinesAdded] = useState<number>(0);
	const [linesDeleted, setLinesDeleted] = useState<number>(0);

	useEffect(() => {
		getCoursesCompleteInfo().then((data) => {
			setCoursesCount(data.count);
			setCoursesDuration(data.completeDuration);
		});

		http.get('/api/gitlab-stats').then(({ data }) => {
			setProjectsCount(data.projects);
			setCommitsCount(data.commits);
			setLinesAdded(data.additions);
			setLinesDeleted(data.deletions);
		});
	}, []);

	const facts: FactsProps = {
		coursesCount,
		coursesDuration,
		projectsCount,
		commitsCount,
		linesAdded,
		linesDeleted,
	};
	//#endregion

	const age = calculateAge(new Date(1998, 10, 20));

	const { t } = useTranslation('index');
	return (
		<LayoutWrapper contactForm noTopPadding>
			<Seo description={t('seo.description').replaceAll('%%age%%', age.toString())} />

			<HeroSection age={age} />
			<ServicesSection />
			<FactsSection {...facts} />
			<PortfolioSection featuredProjects={props.featuredProjects} />
			<TestimonialsSection testimonials={props.testimonials} />

			<ContactSection />
			<FooterSection />
		</LayoutWrapper>
	);
};
export default HomePage;
