import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import Typist from 'react-typist';

type Props = {
	age: number;
};
export const AnimatedText: FC<Props> = (props) => {
	const { t } = useTranslation('index');

	const sentences: string[] = [
		//
		t('hero.text.first').replaceAll('%%age%%', props.age.toString()),
		t('hero.text.second'),
	];
	return (
		<div className='my-6 mx-4 relative'>
			<div className='absolute text-center md:text-left'>
				<Typist avgTypingDelay={55} cursor={{ hideWhenDone: true, hideWhenDoneDelay: 300 }}>
					{sentences[0]}
					<Typist.Delay ms={1000} />
					{' ' + sentences[1]}
				</Typist>
			</div>
			<p className='invisible'>{sentences.join(' ')}</p>
		</div>
	);
};
