import clsx from 'clsx';
import { FC } from 'react';

type Props = {
	text: string;
};
export const ProjectGridItemOverlay: FC<Props> = (props) => {
	const { text } = props;

	return (
		<div
			className={clsx(
				'absolute inset-0',
				'p-4',
				'rounded-xl sm:rounded-2xl lg:rounded-3xl',
				'flex items-center justify-center',
				'bg-black backdrop-filter backdrop-blur-sm bg-opacity-60'
			)}
		>
			<h3 className={clsx('text-main-bg font-bold font-nunito text-2xl text-center')}>{text}</h3>
		</div>
	);
};
