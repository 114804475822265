import Link from 'next/link';
import { FC } from 'react';

type Props = {
	name: string;
	website?: {
		url: string;
		text?: string;
	};
};
export const TestimonialClientInfo: FC<Props> = (props) => {
	const { name, website } = props;

	return (
		<div className='flex flex-col flex-1 justify-center items-stretch'>
			<div className='flex flex-row justify-between'>
				<span className='grow font-bold'>{name}</span>
			</div>

			<span>
				{website && (
					<Link
						href={website.url}
						target='_blank'
						rel='canonical noreferrer noopener'
						className='link link-secondary hover:no-underline'
					>
						{website?.text || website?.url}
					</Link>
				)}
			</span>
		</div>
	);
};
