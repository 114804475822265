import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Testimonial } from '../../../../types/collections/testimonial';
import { Container } from '../../../layout/container';
import { Header } from '../../../ui/typography/header';
import { TestimonialCard } from './testimonial-card';

type Props = {
	testimonials: Testimonial[];
};
export const TestimonialsSection: FC<Props> = ({ testimonials }) => {
	const { t } = useTranslation('index');
	return (
		<Container className='py-20'>
			<Header type='h4' className='text-center pb-10 font-nunito' id='testimonials'>
				{t('testimonials.title')}
			</Header>

			<div className='relative -mx-4 2xl:-mx-16 overflow-hidden'>
				<div className='absolute left-0 top-0 w-4 2xl:w-16 h-full bg-gradient-to-r from-main-bg dark:from-main-bg-dark to-transparent z-20'></div>
				<div className='absolute right-0 top-0 w-4 2xl:w-16 h-full bg-gradient-to-l from-main-bg dark:from-main-bg-dark to-transparent z-20'></div>

				<Swiper
					modules={[Pagination]}
					className='px-4 2xl:px-16'
					slidesPerView={1}
					spaceBetween={16}
					breakpoints={{
						640: { slidesPerView: Math.min(1, testimonials.length), spaceBetween: 24 },
						768: { slidesPerView: Math.min(2, testimonials.length), spaceBetween: 24 },
						1024: { slidesPerView: Math.min(2, testimonials.length), spaceBetween: 32 },
						1280: { slidesPerView: Math.min(3, testimonials.length), spaceBetween: 32 },
					}}
					pagination={{ clickable: true }}
				>
					{testimonials.map((data, i) => {
						return (
							<SwiperSlide
								key={`testimonial-${i}`}
								className='bg-background dark:bg-form-bg-dark shadow-lg dark:shadow-xl rounded-2xl'
							>
								<TestimonialCard testimonial={data} />
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</Container>
	);
};
