import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { FC } from 'react';
import { FactsProps } from '..';
import { SOCIAL_MEDIA } from '../../../../../config/social-media';
import { CardModal } from '../../../../ui/modal/card-model';
import { Header } from '../../../../ui/typography/header';
import { StatItem } from './stat-item';

interface Props {
	open: boolean;
	onClose: () => void;
	data: FactsProps;
}
export const GitStatsDialog: FC<Props> = (props) => {
	const { data } = props;

	const { t } = useTranslation('index');
	return (
		<CardModal open={props.open} onClose={props.onClose}>
			<Header className='text-typography dark:text-typography-dark' type='h4'>
				{t('facts.dialog.title')}
			</Header>

			<p className='text-typography dark:text-typography-dark'>
				{t('facts.dialog.description.1') + ' '} <b>{data.projectsCount}</b>
				{' ' + t('facts.dialog.description.2') + ' '}
				<Link
					href={SOCIAL_MEDIA.find((x) => x.name === 'GitLab')!.url}
					target='_blank'
					rel='canonical noreferrer noopener'
					className='link text-middle dark:text-middle-dark'
				>
					GitLab
				</Link>
				{' ' + t('facts.dialog.description.3')}
				<br /> {t('facts.dialog.description.4')}
			</p>

			<div className='my-4 md:my-8 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3'>
				<div className='m-4'>
					<StatItem
						className='text-typography dark:text-typography-dark'
						caption={t('facts.facts.commits')}
						value={data.commitsCount}
					/>
				</div>
				<div className='m-4'>
					<StatItem
						className='text-typography dark:text-typography-dark'
						caption={t('facts.facts.lines-added')}
						value={data.linesAdded / 1000000}
						decimals={1}
						suffix='M'
					/>
				</div>
				<div className='m-4'>
					<StatItem
						className='text-typography dark:text-typography-dark'
						caption={t('facts.facts.lines-deleted')}
						value={data.linesDeleted / 1000000}
						decimals={1}
						suffix='M'
					/>
				</div>
			</div>

			<p className='text-typography dark:text-typography-dark text-sm'>
				<b>{t('facts.facts.commits')}:</b> {t('facts.dialog.descriptions.commits')}
				<br />
				<b>{t('facts.facts.lines-added')}:</b> {t('facts.dialog.descriptions.lines-added')}
				<br />
				<b>{t('facts.facts.lines-deleted')}:</b> {t('facts.dialog.descriptions.lines-deleted')}
			</p>
		</CardModal>
	);
};
