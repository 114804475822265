import { FC } from 'react';
import { icons } from '../../../assets/icons';

type Props = {
	value: number;
	max?: number;
	activeIcon?: JSX.Element;
	inactiveIcon?: JSX.Element;
};
export const Rating: FC<Props> = (props) => {
	const { value, max, activeIcon, inactiveIcon } = props;

	return (
		<div className='flex flex-row gap-0.5'>
			{[...Array.from(Array(value)).keys()].map((x) => (
				<div key={`active-icon-${x}`} className='w-5 h-5 text-[#ffb300]'>
					{activeIcon}
				</div>
			))}

			{[...Array.from(Array(max! - value)).keys()].map((x) => (
				<div
					key={`inactive-icon-${x}`}
					className='w-5 h-5 text-typography-secondary dark:text-typography-secondary-dark'
				>
					{inactiveIcon}
				</div>
			))}
		</div>
	);
};

Rating.defaultProps = {
	max: 5,
	activeIcon: icons.star,
	inactiveIcon: icons.star,
};
