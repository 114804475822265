import { FC } from 'react';
import { Rating } from '../../../../ui/rating';

type Props = {
	value: number;
};
export const TestimonialRating: FC<Props> = (props) => {
	const { value } = props;

	return (
		<div>
			<Rating value={value} />
		</div>
	);
};
