import { FC } from 'react';
import { Container } from '../../../layout/container';
import { Header } from '../../../ui/typography/header';
import { ServiceCard } from './service-card';
import { Pwa } from '../../../../assets/undraw/pwa';
import { AppWireframe } from '../../../../assets/undraw/app-wireframe';
import { BusinessChat } from '../../../../assets/undraw/business-chat';
import { useTheme } from 'next-themes';
import { DividerTop } from './divider-top';
import { useTranslation } from 'next-i18next';

export const ServicesSection: FC = () => {
	const isDark = useTheme().resolvedTheme === 'dark';

	const { t } = useTranslation('index');
	return (
		<div className='relative'>
			<DividerTop />

			<Container className='py-32 pt-56'>
				<div className='text-center mb-12'>
					<Header component='h3' type='h2'>
						{t('services.title')}
					</Header>
				</div>
				<div className='grid grid-cols-1 lg:grid-cols-3 gap-4 items-center'>
					<ServiceCard
						illustration={
							<AppWireframe
								primary={isDark ? '#F77736' : '#7168CD'}
								secondary={isDark ? '#30363E' : undefined}
								background={isDark ? '#1d222a' : undefined}
								borderDark={isDark ? '#434B56' : undefined}
							/>
						}
						title={t('services.services.1.title')}
						body={t('services.services.1.description')}
					/>
					<ServiceCard
						primary
						illustration={<Pwa primary={'white'} secondary={'#30363E'} />}
						title={t('services.services.2.title')}
						body={t('services.services.2.description')}
					/>
					<ServiceCard
						illustration={
							<BusinessChat
								primary={isDark ? '#F77736' : '#7168CD'}
								secondary={isDark ? '#434B56' : undefined}
								background={isDark ? '#1d222a' : undefined}
								borderDark={isDark ? '#30363E' : undefined}
							/>
						}
						title={t('services.services.3.title')}
						body={t('services.services.3.description')}
					/>
				</div>
			</Container>
		</div>
	);
};
