import moment from 'moment';

export const arrayUniqueByKey = <T>(array: T[], key: keyof T) => [
	...new Map(array.map((item) => [item[key], item])).values(),
];

export const firstOrDefault = <T>(value: T | T[], defaultValue: T = undefined!): T | undefined => {
	if (Array.isArray(value)) {
		if (value.length === 0) return defaultValue;
		else return value[0];
	}

	return value;
};

export function calculateAge(birthday: Date): number {
	const difference = moment.duration(moment().diff(moment(birthday)));
	const years = difference.asYears();
	return Math.floor(years);
}
