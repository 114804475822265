import clsx from 'clsx';
import Link from 'next/link';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';

type Props = {
	content: string;
};
export const Markdown: FC<Props> = ({ content }) => {
	return (
		<ReactMarkdown
			components={{
				p: (props) => <p {...props} />,
				a: ({ href, className, ...props }) => (
					<Link href={href!} className={clsx('link italic', className)} {...props} />
				),
			}}
		>
			{content}
		</ReactMarkdown>
	);
};
