import { Transition } from 'framer-motion';

export const bounce: Transition = {
	y: {
		duration: 0.4,
		yoyo: Infinity,
		ease: 'easeOut',
	},
	backgroundColor: {
		duration: 0,
		yoyo: Infinity,
		ease: 'easeOut',
		repeatDelay: 0.8,
	},
};
