import { useTheme } from 'next-themes';
import { FC } from 'react';
import colors from '../../../../config/colors';
import { Divider } from '../../../ui/divider';

export const DividerBottom: FC = () => {
	const isDark = useTheme().resolvedTheme === 'dark';

	const leftColor = isDark ? colors.dark.primary : colors.light.primary;
	const rightColor = isDark ? colors.dark.secondary : colors.light.secondary;
	const shadowMatrix = isDark
		? undefined
		: '0 0 0 0 0.443137 0 0 0 0 0.407843 0 0 0 0 0.803922 0 0 0 0.745 0';

	const divider = (
		<svg
			viewBox='0 0 1440 320'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className='w-full'
			preserveAspectRatio='none'
		>
			<g filter='url(#filter0_d_9_7)'>
				<path
					d='M0 256L48 218.7C96 181 192 107 288 90.7C384 75 480 117 576 154.7C672 192 768 224 864 234.7C960 245 1056 235 1152 197.3C1248 160 1344 96 1392 64L1440 32V0H1392C1344 0 1248 0 1152 0C1056 0 960 0 864 0C768 0 672 0 576 0C480 0 384 0 288 0C192 0 96 0 48 0H0V256Z'
					fill='url(#paint0_linear_9_7)'
				/>
			</g>
			<defs>
				<filter
					id='filter0_d_9_7'
					x='-70'
					y='-70'
					width='1580'
					height='396'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset />
					<feGaussianBlur stdDeviation='35' />
					<feColorMatrix type='matrix' values={shadowMatrix} />
					<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_9_7' />
					<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_9_7' result='shape' />
				</filter>
				<linearGradient
					id='paint0_linear_9_7'
					x1='0'
					y1='0'
					x2='1440'
					y2='1.0699e-05'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor={leftColor} />
					<stop offset='1' stopColor={rightColor} />
				</linearGradient>
			</defs>
		</svg>
	);

	return (
		<Divider className='bg-main-bg dark:bg-main-bg-dark ' divider={divider} position='bottom' />
	);
};
