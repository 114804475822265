import { FC } from 'react';

type Props = {
	primary?: string;
	secondary?: string;
	background?: string;
	borderDark?: string;
};

export const BusinessChat: FC<Props> = (props) => {
	const primary = props.primary || '#6c63ff';
	const secondary = props.secondary || '#e6e6e6';
	const background = props.background || '#fff';
	const borderDark = props.borderDark || '#2f2e41';

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			data-name='Layer 1'
			width='807.0639'
			height='517.11635'
			viewBox='0 0 807.0639 517.11635'
		>
			<rect x='0.34446' y='0.45733' width='806.71944' height='516.65902' fill={secondary} />
			<rect x='15.46842' y='48' width='776.47152' height='456' fill={background} />
			<rect width='806.71944' height='34.27239' fill={primary} />
			<circle cx='25.46849' cy='17.52186' r='6.35212' fill={background} />
			<circle cx='49.57957' cy='17.52186' r='6.35212' fill={background} />
			<circle cx='73.69064' cy='17.52186' r='6.35212' fill={background} />
			<path
				d='M454.49026,367.59359s2.91628,40.23814,1.5869,42.604,23.3486,44.87049,23.3486,44.87049l39.4535-36.976s-2.38919-51.14828.15247-53.45562S454.49026,367.59359,454.49026,367.59359Z'
				transform='translate(-196.46805 -191.44183)'
				fill='#a0616a'
			/>
			<circle cx='291.57256' cy='160.76167' r='46.11879' fill='#a0616a' />
			<path
				d='M484.46807,426.15l-4.73184-2.65872-23.34812-21.02912s-2.32553-.8674-2.67692,6.406l-.3514,7.27343L438.28584,426.349l-.69161,165.21582,104.25255,5.0367,4.38688-166.25236-23.542-15.71818-4.314-9.19615-26.51912,18.64212Z'
				transform='translate(-196.46805 -191.44183)'
				fill={background}
			/>
			<path
				d='M588.19835,442.096l-11.5297,168.17575H402.5702C380.3846,584.0084,362.6653,448.86816,381.574,444.86312c6.17745-2.136,48.0606-15.292,48.0606-15.292s12.51276-7.90089,16.50572-14.98865a54.95391,54.95391,0,0,1,8.93246-11.51755l.0243-.0243.01207-.02422c.09712-.09711.18208-.19423.2792-.27919a.16306.16306,0,0,1,.085-.07282c.12134-.13348.24267-.25482.37623-.37623v.18208l-.886,30.754,33.35122,114.61733s9.39366-68.802,18.933-90.2108c5.37647-12.06371,8.19215-26.95525,9.62425-37.65967,1.10447-8.2892,1.38359-14.054,1.38359-14.054s.04852.06066.13348.16985v.01215a.0119.0119,0,0,0,.01215.01215c.14563.18208.37623.48549.71609.89809l.01207.01215c2.79146,3.49532,11.74815,14.07834,17.81641,14.36967,7.26984.35194,27.23436,14.68517,27.23436,14.68517C573.93793,435.32383,588.19835,442.096,588.19835,442.096Z'
				transform='translate(-196.46805 -191.44183)'
				fill={borderDark}
			/>
			<path
				d='M532.16649,570.61746s13.13566-45.538,32.29726-39.75193-1.87416,38.79163-1.87416,38.79163Z'
				transform='translate(-196.46805 -191.44183)'
				fill='#a0616a'
			/>
			<path
				d='M455.84442,607.60742s-42.82947-20.295-34.03431-38.27525,37.984,8.09434,37.984,8.09434Z'
				transform='translate(-196.46805 -191.44183)'
				fill='#a0616a'
			/>
			<path
				d='M472.99848,610.27178H462.71881a104.29179,104.29179,0,0,0-12.5492-2.30589c-.97091-.04852-1.28647-1.56559-1.16506-3.96867a75.4561,75.4561,0,0,1,1.954-11.78452c1.46855-6.55375,3.43466-13.64151,4.69683-18.15626v-.01215c.74031-2.63361,1.2258-4.38127,1.23795-4.63616.03637-.801,6.76006.30341,11.39622,1.153,2.43938.44905,4.2963.82528,4.2963.82528l.2306,21.88218Z'
				transform='translate(-196.46805 -191.44183)'
				fill={background}
			/>
			<path
				d='M610.20188,590.18585c-2.96132,8.38638-5.09736,15.025-6.845,20.08593H468.7264c-.449-4.63608-1.0195-10.98354-1.38358-17.27025-.59468-10.06115-.69179-19.9646.94669-22.44042a1.15787,1.15787,0,0,1,.72816-.57038c3.14339-.619,38.6548-1.17728,63.80171-1.505,3.49533-.03637,6.78428-.07282,9.76989-.10919,9.066-.10926,15.2071-.16993,15.2071-.16993s.48542-16.60275,1.96611-37.76886c2.60931-37.21055,8.31349-88.536,19.9403-88.7544l8.49557.41267s4.84246.2306,6.91783,7.6217S618.64885,566.289,610.20188,590.18585Z'
				transform='translate(-196.46805 -191.44183)'
				fill={borderDark}
			/>
			<path
				d='M450.27,308.99647l-8.58692-3.60066s19.15842-17.3925,44.50852-14.57481l-6.80594-7.49684s17.788-5.51206,32.87364,11.942c7.93015,9.1752,17.09765,19.9527,22.5621,31.8585l9.36041.45224-4.28416,7.62463,13.29593,8.474L539.22694,341.594a39.47325,39.47325,0,0,1-2.3051,20.1717l-3.60049,8.5868s-11.485-26.0409-11.33147-29.21927l-.3839,7.94584s-8.395-7.57352-8.16466-12.341l-5.03622,5.33172-1.96144-8.85563-29.822,7.32008,5.113-6.92092-18.39063,1.50081,7.57371-8.395s-21.15827,9.33151-22.37528,18.0336c-1.21675,8.702-12.08372,19.32712-12.08372,19.32712l-4.38368-8.17616S426.65126,319.80192,450.27,308.99647Z'
				transform='translate(-196.46805 -191.44183)'
				fill={borderDark}
			/>
			<path
				d='M894.13062,611.2719H286.20289V268.44183H894.13062Zm-605.92773-2H892.13062V270.44183H288.20289Z'
				transform='translate(-196.46805 -191.44183)'
				fill={secondary}
			/>
			<path
				d='M776.27484,310.95036c-5.74833-9.7129-17.12094-10.16556-17.12094-10.16556s-11.082-1.41714-18.191,13.37572c-6.62615,13.78816-15.7711,27.1009-1.47226,30.32862l2.5828-8.03878,1.5995,8.63726a55.94805,55.94805,0,0,0,6.11795.10455c15.31291-.49439,29.89616.14466,29.42659-5.35027C778.59331,332.53719,781.80588,320.29611,776.27484,310.95036Z'
				transform='translate(-196.46805 -191.44183)'
				fill={borderDark}
			/>
			<path
				d='M755.06876,344.04322a6.06476,6.06476,0,0,1-.52378,3.19543c-1.077,1.88157-3.70613,2.16324-5.28757,3.64624-1.66312,1.55958-1.85137,4.09068-1.93063,6.36925a17.60551,17.60551,0,0,0,.71623,7.06206,17.16845,17.16845,0,0,0,2.00576,3.4459q1.4483,2.08772,2.92339,4.15666a17.94591,17.94591,0,0,0,1.75046,2.19672,14.90829,14.90829,0,0,0,6.589,3.4955,8.23568,8.23568,0,0,0,4.78736.317,9.16513,9.16513,0,0,0,2.784-1.71184,14.91792,14.91792,0,0,0,3.15123-3.0543,14.2207,14.2207,0,0,0,1.92695-6.51309,140.54574,140.54574,0,0,0,.92641-20.24664,4.96216,4.96216,0,0,0-.38654-2.15121c-.80079-1.56365-2.9456-1.89749-4.07573-3.24253-.96624-1.15-1.00564-2.79116-.99739-4.29315l.021-3.82372a1.6018,1.6018,0,0,0-.18584-.93157,1.57828,1.57828,0,0,0-1.09684-.51173,36.9757,36.9757,0,0,0-7.19373-.48128c-1.95217.05966-5.69782-.10375-7.38581.98355-1.53218.98693.38621,3.71273.74111,5.24386A34.16351,34.16351,0,0,1,755.06876,344.04322Z'
				transform='translate(-196.46805 -191.44183)'
				fill='#ffb8b8'
			/>
			<circle cx='563.08149' cy='130.57993' r='15.45521' fill='#ffb8b8' />
			<path
				d='M750.7332,350.45222c-1.67008-.9691-3.93761-.18432-5.15385,1.31538a9.339,9.339,0,0,0-1.69568,5.43786,35.49883,35.49883,0,0,1-.41311,5.76892c-.92823,4.16986-4.37265,7.598-4.62881,11.86225-.252,4.19513,2.62474,7.84969,4.04,11.80691a19.68856,19.68856,0,0,1,1.05477,7.68523,3.08256,3.08256,0,0,0,.495,2.39828,2.73507,2.73507,0,0,0,1.06973.57634c6.82906,2.40261,14.20546,2.61047,21.44253,2.79378l11.95323.30276a3.80991,3.80991,0,0,0,2.1108-.35347c1.07239-.65753,1.21592-2.1314,1.23415-3.3892.157-10.83295-.30376-21.66431-.76443-32.4886a68.97338,68.97338,0,0,0-1.16874-11.70718,3.3587,3.3587,0,0,0-1.46138-2.49528,3.22459,3.22459,0,0,0-2.46354.16452c-3.7049,1.37785-15.4804,6.65868-16.641,4.779C758.396,352.72843,750.07748,352.08486,750.7332,350.45222Z'
				transform='translate(-196.46805 -191.44183)'
				fill={background}
			/>
			<path
				d='M751.33531,350.71936c-.304.21534-.60379.44334-.89093.684a17.24794,17.24794,0,0,0-2.87117,3.02317,23.95023,23.95023,0,0,0-3.2512,6.135,59.39812,59.39812,0,0,0-2.59248,11.96607,28.87958,28.87958,0,0,0-.40957,4.50944c.08866,5.55657,3.37363,10.70358,3.37786,16.2644,0,.21112-.00423.418-.01689.62912a18.26465,18.26465,0,0,1-.32091,2.71073c-.24067,1.30471-.5869,2.59251-.92891,3.87609a37.017,37.017,0,0,1-3.47075,9.37779c-.16889.28711-.35045.57-.53623.84446H727.60162c.09289-.28289.19-.56157.2829-.84446q3.02107-8.94923,6.038-17.89421c.61645-1.824,1.23292-3.65652,1.66782-5.53124a52.38865,52.38865,0,0,0,1.01758-7.02172q.69669-7.34053.91625-14.71059a17.88675,17.88675,0,0,1,.66291-5.23989,17.14222,17.14222,0,0,1,2.10272-3.83388q1.83037-2.75509,3.66077-5.51013a8.46485,8.46485,0,0,1,2.44051-2.71072c.874-.53624,1.91271-.73045,2.83317-1.18225a14.48356,14.48356,0,0,0,1.6636-1.102c1.37648-.97535,2.99362-2.00137,4.1801-1.00491a2.32824,2.32824,0,0,1,.48979.56157,2.82638,2.82638,0,0,1,.18155.32513C756.87922,347.50618,752.868,349.63422,751.33531,350.71936Z'
				transform='translate(-196.46805 -191.44183)'
				fill={borderDark}
			/>
			<path
				d='M790.282,410.7397H735.52694c.29557-.27023.58691-.55312.8698-.84446.04223-.04222.08023-.08445.12245-.12667,2.09427-2.1745,2.5714-4.84722,3.62275-7.6762q1.18439-3.19841,2.36872-6.39261a3.30653,3.30653,0,0,1,.60379-1.1358,1.87323,1.87323,0,0,1,.8318-.49824,3.22746,3.22746,0,0,1,.73468-.13511,8.18891,8.18891,0,0,1,1.06824,0q14.738.76,29.45494,1.8536,1.9.1457,3.80009.29134c1.03447.76847,2.05627,1.58338,3.03162,2.44472a27.13555,27.13555,0,0,1,7.49463,9.94357c.19.46867.36311.94579.50668,1.43137C790.13,410.1739,790.21026,410.45679,790.282,410.7397Z'
				transform='translate(-196.46805 -191.44183)'
				fill={borderDark}
			/>
			<polygon
				points='577.265 118.572 566.03 112.687 550.514 115.094 547.304 129.273 555.295 128.966 557.527 123.757 557.527 128.879 561.215 128.738 563.355 120.445 564.692 129.273 577.8 129.005 577.265 118.572'
				fill={borderDark}
			/>
			<path
				d='M790.282,410.7397h-12.363c-.08866-.27866-.17312-.56157-.25334-.84446a66.40159,66.40159,0,0,1-1.91694-9.57623c-.13089-.92469-.24489-1.85361-.35045-2.7783-.06756-.5869-.13089-1.16959-.19422-1.75647-.70935-6.51927-1.254-13.10188-3.247-19.34246-.24067-.75579-.50246-1.50738-.7558-2.25894a26.64073,26.64073,0,0,1-1.0978-3.97319c-.54046-3.09075-.13512-6.29128-.69247-9.37779-.31245-1.72272-.93313-3.23852-1.30892-4.81766a9.75721,9.75721,0,0,1-.27868-2.91762,6.28357,6.28357,0,0,1,3.28919-1.62981,1.73336,1.73336,0,0,0,1.16537-.46867,1.46263,1.46263,0,0,0,.19422-.86134,41.362,41.362,0,0,0-.342-5.9577c-.08446-.69246-.19-1.38493-.31668-2.07315-.00422-.03378-.01266-.06334-.01689-.0971a8.17879,8.17879,0,0,1,3.3103-.44757c1.22026.00423,2.43628.00423,3.65231.00846q3.37573.01267,6.75149.0211a14.86487,14.86487,0,0,1,6.16458.89091,9.81161,9.81161,0,0,1,4.45032,4.5601,26.24726,26.24726,0,0,1,2.00983,6.15193,8.24107,8.24107,0,0,1,.21112,1.77761c.09288,4.75434-3.45386,9.54244-3.80855,14.40234-.114,1.57492.01688,3.16251-.12666,4.73743-.14357,1.57915-.5658,3.12453-.6418,4.70368-.00423.08022-.00846.16467-.00846.2449a16.92965,16.92965,0,0,0,1.24559,6.00836c1.07246,3.01052,2.37717,5.92813,3.7241,8.82886,1.30048,2.80786,2.63472,5.603,3.83387,8.44888a14.46647,14.46647,0,0,1,1.24559,4.17588,5.79468,5.79468,0,0,1-.63757,3.37363,3.95888,3.95888,0,0,1-.50246.71358l-.00845.00845c-.038.04221-.076.08445-.114.12244H790.282Z'
				transform='translate(-196.46805 -191.44183)'
				fill={borderDark}
			/>
			<path
				d='M802.0581,406.40759a4.67929,4.67929,0,0,1-.45179,1.61716c-.02111.05067-.04221.09711-.06335.14777-.2449.57846-.494,1.14846-.75158,1.72272-.12667.27866-.25333.56157-.38422.84446H793.415a7.82664,7.82664,0,0,0,.7009-.84446c1.07246-1.4905,1.69315-3.30186,2.78672-4.91057a3.78094,3.78094,0,0,1,.51091-.608C799.16579,402.67927,802.16786,403.02551,802.0581,406.40759Z'
				transform='translate(-196.46805 -191.44183)'
				fill='#ffb8b8'
			/>
			<path
				d='M806.60975,410.7397a8.28236,8.28236,0,0,0,.2238-.84446c.05066-.2238.09288-.44334.12666-.66714.2238-1.47358.21957-2.981.57-4.42921.494-2.07315,1.976-4.52632.75578-6.27014a6.625,6.625,0,0,0,2.62208-1.17382,2.25459,2.25459,0,0,0,.70934-2.59251c-.43491-.84869-1.61293-1.41027-1.45248-2.35184a2.24362,2.24362,0,0,1,.41378-.81913,6.135,6.135,0,0,0-.10556-6.73039c-.38422-.54889-.85714-1.03024-1.20757-1.60447a9.56867,9.56867,0,0,1-.874-2.22518,41.44894,41.44894,0,0,0-5.3328-11.054,7.33492,7.33492,0,0,1-1.178-2.1787,8.99458,8.99458,0,0,1-.09288-2.58829,17.35118,17.35118,0,0,0-3.0274-9.61424c-.13512-.21112-.27446-.418-.41378-.6249a55.6904,55.6904,0,0,0-6.16458-7.18217,3.95307,3.95307,0,0,0-2.17028,1.57492,4.68049,4.68049,0,0,0-.418,1.57069c-.59956,4.18011-1.11893,8.38553-1.52425,12.58674a30.76846,30.76846,0,0,0-.16891,6.38415,10.30563,10.30563,0,0,0,2.39828,5.81838c.89936.988,2.03939,1.72271,3.01475,2.63473q.23433.22165.456.456a14.95045,14.95045,0,0,1,1.862,2.44472,21.07823,21.07823,0,0,1,3.09919,12.63741,20.53235,20.53235,0,0,1-.31667,2.3814,9.84905,9.84905,0,0,1-1.12313,3.209c-.44334.73046-1.026,1.368-1.42714,2.12384a2.68572,2.68572,0,0,0-.19424,2.45317,4.99563,4.99563,0,0,1,1.74382.31245,7.40159,7.40159,0,0,1,3.29341,2.51229,10.952,10.952,0,0,1,.836,1.28358,15.80993,15.80993,0,0,1,.836,1.72271c.10133.23222.19844.4729.29134.71358a.69962.69962,0,0,1,.05067.13089Z'
				transform='translate(-196.46805 -191.44183)'
				fill={borderDark}
			/>
			<path
				d='M873.99683,412.31536H655.33277V288.44183H873.99683Zm-216.66406-2H871.99683V290.44183H657.33277Z'
				transform='translate(-196.46805 -191.44183)'
				fill={secondary}
			/>
			<circle cx='403.37333' cy='460.33374' r='22.49773' fill={secondary} />
			<circle cx='403.37333' cy='460.33374' r='17.24826' fill={background} />
			<path
				d='M608.4822,661.196a.78172.78172,0,0,1-.55534-.23029L590.64308,643.6819a.78563.78563,0,0,1,1.11068-1.11068L609.03754,659.855a.78563.78563,0,0,1-.55534,1.341Z'
				transform='translate(-196.46805 -191.44183)'
				fill={primary}
			/>
			<path
				d='M599.4475,654.70719l-2.543-2.543a.0982.0982,0,0,0-.16645.083,3.14791,3.14791,0,0,0,2.62645,2.62645.0982.0982,0,0,0,.083-.16645Z'
				transform='translate(-196.46805 -191.44183)'
				fill={primary}
			/>
			<path
				d='M600.23313,648.82972l2.5469,2.54592a.0982.0982,0,0,0,.16694-.083,3.1489,3.1489,0,0,0-2.62939-2.6294.0982.0982,0,0,0-.08445.16646Z'
				transform='translate(-196.46805 -191.44183)'
				fill={primary}
			/>
			<path
				d='M611.3792,652.62086a1.58109,1.58109,0,0,0-.00491-1.70678A16.64213,16.64213,0,0,0,606.5,645.97789a12.35131,12.35131,0,0,0-6.67537-2.0657,11.12358,11.12358,0,0,0-3.52648.57891.19641.19641,0,0,0-.0766.32554l2.31956,2.31956a.1964.1964,0,0,0,.18757.05156,4.71376,4.71376,0,0,1,5.69579,5.69579.19642.19642,0,0,0,.05156.18708l3.33646,3.33891a.19639.19639,0,0,0,.26514.01178A16.88137,16.88137,0,0,0,611.3792,652.62086Z'
				transform='translate(-196.46805 -191.44183)'
				fill={primary}
			/>
			<path
				d='M599.84031,656.48221a4.71376,4.71376,0,0,1-4.58118-5.82493.19643.19643,0,0,0-.05156-.18708l-3.282-3.28342a.1964.1964,0,0,0-.26564-.0113,19.63328,19.63328,0,0,0-3.32271,3.718,1.56734,1.56734,0,0,0-.03143,1.74507,16.40009,16.40009,0,0,0,4.82571,4.94208,12.16778,12.16778,0,0,0,6.69305,2.04411,11.69672,11.69672,0,0,0,3.56674-.56713.1964.1964,0,0,0,.07906-.326l-2.33086-2.33037a.19641.19641,0,0,0-.18708-.05155A4.71364,4.71364,0,0,1,599.84031,656.48221Z'
				transform='translate(-196.46805 -191.44183)'
				fill={primary}
			/>
			<circle cx='318.01431' cy='460.33374' r='22.49773' fill={secondary} />
			<circle cx='318.01431' cy='460.33374' r='17.24826' fill={background} />
			<path
				d='M521.10851,662.774a9.252,9.252,0,0,1-4.321-1.473,28.09351,28.09351,0,0,1-6.78634-5.07613,25.753,25.753,0,0,1-5.09625-6.78437c-1.80891-3.28981-1.50055-5.01426-1.15586-5.75128a4.59091,4.59091,0,0,1,1.79958-1.93067,8.65648,8.65648,0,0,1,1.40627-.74635c.0491-.02111.09477-.04124.13552-.05941a1.31415,1.31415,0,0,1,1.07778-.0982,3.15681,3.15681,0,0,1,1.02426.78562,17.02329,17.02329,0,0,1,2.56114,3.80194,3.78743,3.78743,0,0,1,.50231,1.557,2.4606,2.4606,0,0,1-.626,1.46372c-.06433.08789-.12816.17185-.19.25336-.37366.491-.45566.63292-.40165.88629a9.31455,9.31455,0,0,0,2.268,3.364,8.92829,8.92829,0,0,0,3.32516,2.213c.26417.05647.409-.029.91575-.41589.07267-.05548.1473-.11293.22537-.17038a2.47386,2.47386,0,0,1,1.48582-.66484h.00295a3.558,3.558,0,0,1,1.56437.549,18.75716,18.75716,0,0,1,3.78771,2.54248,3.156,3.156,0,0,1,.78808,1.02181,1.32451,1.32451,0,0,1-.09821,1.08023c-.01816.04076-.0383.08544-.05941.135a8.66631,8.66631,0,0,1-.75076,1.40333,4.59174,4.59174,0,0,1-1.93363,1.79613A3.31021,3.31021,0,0,1,521.10851,662.774Z'
				transform='translate(-196.46805 -191.44183)'
				fill={primary}
			/>
			<circle cx='489.39405' cy='460.33374' r='22.49773' fill={secondary} />
			<circle cx='489.39405' cy='460.33374' r='17.24826' fill={background} />
			<path
				d='M689.0046,661.98872h-6.285a.78563.78563,0,1,1,0-1.57125h6.285a.78563.78563,0,1,1,0,1.57125Z'
				transform='translate(-196.46805 -191.44183)'
				fill={primary}
			/>
			<path
				d='M685.8621,658.06059a7.07866,7.07866,0,0,1-7.07064-7.07064V649.4187a.78563.78563,0,0,1,1.57125,0V650.99a5.49939,5.49939,0,1,0,10.99877,0V649.4187a.78563.78563,0,0,1,1.57125,0V650.99A7.07868,7.07868,0,0,1,685.8621,658.06059Z'
				transform='translate(-196.46805 -191.44183)'
				fill={primary}
			/>
			<path
				d='M685.8621,661.98872a.78562.78562,0,0,1-.78563-.78562V657.275a.78563.78563,0,1,1,1.57125,0v3.92813A.7856.7856,0,0,1,685.8621,661.98872Z'
				transform='translate(-196.46805 -191.44183)'
				fill={primary}
			/>
			<path
				d='M685.8621,654.91809a3.87071,3.87071,0,0,1-2.7767-1.18335,3.97186,3.97186,0,0,1-1.15144-2.79389v-5.45028a3.91291,3.91291,0,0,1,3.89763-3.92813q.01526-.00006.03051,0a3.88633,3.88633,0,0,1,3.92813,3.92813v5.45028A3.95757,3.95757,0,0,1,685.8621,654.91809Z'
				transform='translate(-196.46805 -191.44183)'
				fill={primary}
			/>
		</svg>
	);
};
