import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { Card } from '../../../ui/card';
import { Header } from '../../../ui/typography/header';
import classes from './service-card.module.scss';

type Props = {
	illustration: JSX.Element;
	title: string;
	body: string;
	primary?: boolean;
};
export const ServiceCard: FC<Props> = (props) => {
	const { primary, illustration } = props;

	const [mounted, setMounted] = useState<boolean>(false);
	useEffect(() => setMounted(true), []);

	return (
		<div className='text-justify'>
			<Card
				className={clsx('p-0', {
					'bg-gradient-to-r from-primary dark:from-primary-dark to-secondary dark:to-secondary-dark text-main-bg dark:text-main-bg-dark shadow-middle dark:shadow-middle-dark':
						primary,
					[classes.cardPrimary]: primary,
				})}
			>
				<div className={classes.contentWrapper}>
					<div className={classes.illustration}>
						<div className='h-full'>{mounted && illustration}</div>
					</div>
					<div className={classes.body}>
						<Header type='h5' component='h3' notBold className={classes.header}>
							{props.title}
						</Header>
						<p className={classes.text}>{props.body}</p>
					</div>
				</div>
			</Card>
		</div>
	);
};
