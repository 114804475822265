import { StrapiImage, StrapiResponse } from '../types/strapi';

export const strapiUrl = (path: string): string => {
	return path;
};

type ImageFormat = 'original' | 'large' | 'medium' | 'small' | 'thumbnail';
export function strapiImageUrl(image: StrapiImage, format: ImageFormat = 'large'): string {
	let imageUrl: string;

	switch (format) {
		case 'original':
			imageUrl = image.url;
			break;
		case 'large':
			if (!image.formats.large) {
				return strapiImageUrl(image, 'medium');
			}
			imageUrl = image.formats.large.url;
			break;
		case 'medium':
			if (!image.formats.medium) {
				return strapiImageUrl(image, 'small');
			}
			imageUrl = image.formats.medium.url;
			break;
		case 'small':
			if (!image.formats.small) {
				return strapiImageUrl(image, 'thumbnail');
			}
			imageUrl = image.formats.small.url;
			break;
		case 'thumbnail':
			imageUrl = image.formats.thumbnail!.url;
			break;
	}

	return strapiUrl(imageUrl);
}

export const strapiResponseToObject = <T = any>(res: StrapiResponse<T>): T => {
	const obj: any = {};
	obj['id'] = res.data.id;
	Object.keys(res.data.attributes).forEach((attr) => {
		let value: any = res.data.attributes[attr as keyof T];
		if (value && value.attributes) {
			value = strapiResponseToObject(value);
		} else if (value && Array.isArray(value.data)) {
			value = strapiResponseToArray(value);
		}
		obj[attr as keyof T] = value;
	});
	return obj as T;
};

const decodeValue = (value: any): any => {
	if (value) {
		if (value.data === null) {
			value = null;
		} else if (!Array.isArray(value.data) && typeof value.data === 'object') {
			value = strapiResponseToObject(value);
		} else if (value.attributes) {
			if (Array.isArray(value.data)) {
				value = strapiResponseToArray(value);
			} else {
				value = strapiResponseToObject(value);
			}
		} else if (Array.isArray(value.data)) {
			value = strapiResponseToArray(value);
		}
	} else {
		value = null;
	}

	return value;
};

export const strapiResponseToArray = <T = any>(res: StrapiResponse<T[]>): T[] => {
	if (Array.isArray(res.data)) {
		const objs: T[] = res.data.map((x) => {
			const obj: any = {};
			obj['id'] = x.id;
			Object.keys(x.attributes).forEach((attr) => {
				const value = x.attributes[attr as keyof T];
				obj[attr as keyof T] = decodeValue(value);
			});
			return obj as T;
		});
		return objs;
	}
	return [];
};
