import { FC } from 'react';
import textBackend from '../../../../assets/svg/text-backend';
import textFrontend from '../../../../assets/svg/text-frontend';

export const BackgroundTexts: FC = () => {
	return (
		<div className='absolute top-0 left-0 w-full h-full z-10 hidden md:flex flex-col justify-evenly text-typography dark:text-typography-dark opacity-20 dark:opacity-10 overflow-hidden'>
			<div
				className='w-full max-w-[90%] flex flex-col justify-center'
				style={{ alignSelf: 'flex-start' }}
			>
				<div className='ml-10'>{textBackend}</div>
			</div>
			<div
				className='max-w-[90%] w-full flex flex-col justify-center'
				style={{ alignSelf: 'flex-end' }}
			>
				<div className='mr-10'>{textFrontend}</div>
			</div>
		</div>
	);
};
