import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { Container } from '../../layout/container';
import { Markdown } from '../../util/markdown';
import { Header, HeaderType } from '../typography/header';

type Props = {
	component?: HeaderType;
	type?: HeaderType;
	header: JSX.Element | string;
	headerClassName?: string;
	subtitle?: JSX.Element;
	description?: string;
	descriptionClassName?: string;
	className?: string;
	markdown?: boolean;
};
export const Heading: FC<PropsWithChildren<Props>> = (props) => {
	return (
		<Container className={clsx('flex flex-col items-center', props.className)}>
			<Header
				component={props.component}
				type={props.type!}
				className={clsx('text-center', props.headerClassName)}
			>
				{props.header}
			</Header>

			{props.subtitle}

			{props.description && props.description !== '' && (
				<div
					className={clsx(
						'text-typography-secondary dark:text-typography-secondary-dark',
						'text-lg text-center',
						'max-w-screen-md',
						props.descriptionClassName
					)}
				>
					{props.markdown && <Markdown content={props.description} />}
					{!props.markdown && <p>{props.description}</p>}
				</div>
			)}

			{props.children}
		</Container>
	);
};
Heading.defaultProps = {
	type: 'h2',
	className: 'py-12 md:py-18 lg:py-24',
};
