import clsx from 'clsx';
import Image from 'next/image';
import { FC } from 'react';
import { StrapiImage } from '../../../../../types/strapi';
import { strapiImageUrl } from '../../../../../util/strapi';

type Props = {
	icon?: StrapiImage;
	name: string;
};
export const TestimonialAvatar: FC<Props> = (props) => {
	const renderName = (name: string) => (
		<div
			className={clsx(
				'w-full h-full rounded-full bg-clip-content ',
				'flex items-center justify-center',
				'bg-main-bg dark:bg-main-bg-dark',
				'border-2 border-transparent'
			)}
		>
			{name
				.split(' ')
				.map((x) => x.charAt(0).toUpperCase())
				.join('')}
		</div>
	);

	const renderAvatarImage = (icon: StrapiImage) => {
		return (
			<div
				className={clsx(
					' w-full h-full',
					'border-2 border-transparent',
					'rounded-full overflow-hidden'
				)}
			>
				<div className={clsx('relative w-full h-full', 'bg-background dark:bg-background-dark')}>
					<Image src={strapiImageUrl(icon)} alt={props.name} fill sizes='56px' />
				</div>
			</div>
		);
	};

	return (
		<div
			className={clsx(
				'w-14 h-14',
				'rounded-full',
				'bg-gradient-to-r from-primary dark:from-primary-dark to-secondary dark:to-secondary-dark'
			)}
		>
			{props.icon ? renderAvatarImage(props.icon) : renderName(props.name)}
		</div>
	);
};
