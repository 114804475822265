import { FC } from 'react';

export const AnimatedName: FC = () => {
	return (
		<div className='text-center md:text-left'>
			<svg
				id='hero-name'
				width='724'
				height='88'
				viewBox='0 0 724 88'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M38.143 84.904C33.3217 84.904 30.911 82.418 30.911 77.446V16.426H8.87601C4.73268 16.426 2.66101 14.392 2.66101 10.324C2.66101 6.33131 4.73268 4.33498 8.87601 4.33498H67.41C71.5533 4.33498 73.625 6.33131 73.625 10.324C73.625 14.392 71.5533 16.426 67.41 16.426H45.375V77.446C45.375 82.418 42.9643 84.904 38.143 84.904Z'
					stroke='currentColor'
					strokeWidth='2'
				/>
				<path
					d='M96.8995 85.13C91.1741 85.13 86.2021 83.9623 81.9835 81.627C77.7648 79.2917 74.4878 75.977 72.1525 71.683C69.8171 67.3136 68.6495 62.191 68.6495 56.315C68.6495 50.439 69.8171 45.354 72.1525 41.06C74.4878 36.766 77.7648 33.4513 81.9835 31.116C86.2021 28.7806 91.1741 27.613 96.8995 27.613C102.625 27.613 107.597 28.7806 111.815 31.116C116.034 33.4513 119.311 36.766 121.646 41.06C123.982 45.354 125.149 50.439 125.149 56.315C125.149 62.191 123.982 67.3136 121.646 71.683C119.311 75.977 116.034 79.2917 111.815 81.627C107.597 83.9623 102.625 85.13 96.8995 85.13ZM96.8995 74.395C101.118 74.395 104.508 72.8883 107.069 69.875C109.631 66.7863 110.911 62.2663 110.911 56.315C110.911 50.2883 109.631 45.806 107.069 42.868C104.508 39.8546 101.118 38.348 96.8995 38.348C92.6808 38.348 89.2908 39.8546 86.7295 42.868C84.1681 45.806 82.8875 50.2883 82.8875 56.315C82.8875 62.2663 84.1681 66.7863 86.7295 69.875C89.2908 72.8883 92.6808 74.395 96.8995 74.395Z'
					stroke='currentColor'
					strokeWidth='2'
				/>
				<path
					d='M168.674 85.13C164.606 85.13 160.99 84.2636 157.826 82.531C154.662 80.723 152.289 78.3123 150.707 75.299V77.672C150.707 82.4933 148.409 84.904 143.814 84.904C139.143 84.904 136.808 82.4933 136.808 77.672V10.55C136.808 5.80397 139.143 3.43097 143.814 3.43097C148.56 3.43097 150.933 5.80397 150.933 10.55V36.879C152.59 34.0916 154.963 31.8693 158.052 30.212C161.216 28.4793 164.757 27.613 168.674 27.613C173.571 27.613 177.827 28.7806 181.443 31.116C185.059 33.4513 187.884 36.766 189.918 41.06C191.952 45.2786 192.969 50.3637 192.969 56.315C192.969 62.191 191.952 67.3136 189.918 71.683C187.884 75.977 185.021 79.2917 181.33 81.627C177.714 83.9623 173.495 85.13 168.674 85.13ZM164.719 74.395C168.938 74.395 172.328 72.8883 174.889 69.875C177.45 66.7863 178.731 62.2663 178.731 56.315C178.731 50.2883 177.45 45.806 174.889 42.868C172.328 39.8546 168.938 38.348 164.719 38.348C160.5 38.348 157.11 39.8546 154.549 42.868C151.988 45.806 150.707 50.2883 150.707 56.315C150.707 62.2663 151.988 66.7863 154.549 69.875C157.11 72.8883 160.5 74.395 164.719 74.395Z'
					stroke='currentColor'
					strokeWidth='2'
				/>
				<path
					d='M211.57 17.443C208.933 17.443 206.861 16.8026 205.355 15.522C203.923 14.166 203.208 12.3203 203.208 9.98498C203.208 7.57431 203.923 5.72864 205.355 4.44798C206.861 3.16731 208.933 2.52698 211.57 2.52698C217.144 2.52698 219.932 5.01298 219.932 9.98498C219.932 14.957 217.144 17.443 211.57 17.443ZM211.57 84.791C209.31 84.791 207.577 84.113 206.372 82.757C205.166 81.401 204.564 79.5176 204.564 77.107V35.636C204.564 30.5133 206.899 27.952 211.57 27.952C216.316 27.952 218.689 30.5133 218.689 35.636V77.107C218.689 79.5176 218.086 81.401 216.881 82.757C215.675 84.113 213.905 84.791 211.57 84.791Z'
					stroke='currentColor'
					strokeWidth='2'
				/>
				<path
					d='M251.559 85.13C247.566 85.13 243.988 84.3766 240.824 82.87C237.735 81.288 235.287 79.1786 233.479 76.542C231.746 73.9053 230.88 70.9296 230.88 67.615C230.88 63.547 231.934 60.3453 234.044 58.01C236.153 55.5993 239.581 53.8666 244.327 52.812C249.073 51.7573 255.438 51.23 263.424 51.23H267.379V48.857C267.379 45.0903 266.55 42.3783 264.893 40.721C263.235 39.0636 260.448 38.235 256.531 38.235C254.346 38.235 251.973 38.4986 249.412 39.026C246.926 39.5533 244.289 40.4573 241.502 41.738C239.694 42.5666 238.187 42.6796 236.982 42.077C235.776 41.399 234.948 40.382 234.496 39.026C234.119 37.5946 234.194 36.1633 234.722 34.732C235.324 33.3006 236.492 32.246 238.225 31.568C241.69 30.1366 245.005 29.1196 248.169 28.517C251.408 27.9143 254.346 27.613 256.983 27.613C265.043 27.613 271.032 29.4963 274.95 33.263C278.867 36.9543 280.826 42.7173 280.826 50.552V77.672C280.826 82.4933 278.641 84.904 274.272 84.904C269.827 84.904 267.605 82.4933 267.605 77.672V75.186C266.324 78.2746 264.29 80.723 261.503 82.531C258.715 84.2636 255.401 85.13 251.559 85.13ZM254.497 75.412C258.188 75.412 261.239 74.1313 263.65 71.57C266.136 69.0086 267.379 65.7693 267.379 61.852V59.366H263.536C256.455 59.366 251.521 59.931 248.734 61.061C246.022 62.1156 244.666 64.0743 244.666 66.937C244.666 69.423 245.532 71.457 247.265 73.039C248.997 74.621 251.408 75.412 254.497 75.412Z'
					stroke='currentColor'
					strokeWidth='2'
				/>
				<path
					d='M315.389 85.13C312.15 85.13 308.722 84.791 305.106 84.113C301.49 83.435 298.251 82.2673 295.388 80.61C293.806 79.706 292.827 78.6136 292.45 77.333C292.074 75.977 292.111 74.734 292.563 73.604C293.091 72.3986 293.919 71.5323 295.049 71.005C296.255 70.4776 297.648 70.5906 299.23 71.344C302.244 72.7753 305.069 73.7923 307.705 74.395C310.342 74.9223 312.941 75.186 315.502 75.186C319.118 75.186 321.793 74.5833 323.525 73.378C325.333 72.0973 326.237 70.44 326.237 68.406C326.237 65.242 323.902 63.208 319.231 62.304L307.931 60.157C298.364 58.349 293.58 53.377 293.58 45.241C293.58 41.625 294.56 38.4986 296.518 35.862C298.552 33.2253 301.34 31.1913 304.88 29.76C308.421 28.3286 312.489 27.613 317.084 27.613C323.714 27.613 329.627 29.082 334.825 32.02C336.257 32.7733 337.085 33.828 337.311 35.184C337.613 36.4646 337.462 37.7076 336.859 38.913C336.332 40.043 335.466 40.8716 334.26 41.399C333.055 41.851 331.661 41.7003 330.079 40.947C327.744 39.7416 325.484 38.8753 323.299 38.348C321.19 37.8206 319.156 37.557 317.197 37.557C313.506 37.557 310.756 38.1973 308.948 39.478C307.216 40.7586 306.349 42.4536 306.349 44.563C306.349 47.8776 308.496 49.9116 312.79 50.665L324.09 52.812C328.987 53.716 332.678 55.3733 335.164 57.784C337.726 60.1946 339.006 63.434 339.006 67.502C339.006 73.0013 336.859 77.333 332.565 80.497C328.271 83.5856 322.546 85.13 315.389 85.13Z'
					stroke='currentColor'
					strokeWidth='2'
				/>
				<path
					d='M410.396 84.904C406.554 84.904 403.917 82.87 402.486 78.802L378.869 12.019C378.04 9.60831 378.191 7.57431 379.321 5.91698C380.526 4.25964 382.523 3.43097 385.31 3.43097C387.344 3.43097 388.926 3.92064 390.056 4.89997C391.186 5.80398 392.09 7.27298 392.768 9.30698L410.848 63.095L429.832 9.41998C431.263 5.42731 433.674 3.43097 437.064 3.43097C438.872 3.43097 440.303 3.92064 441.358 4.89997C442.413 5.87931 443.317 7.42365 444.07 9.53298L462.715 63.886L481.36 9.30698C482.038 7.34831 482.942 5.87931 484.072 4.89997C485.202 3.92064 486.746 3.43097 488.705 3.43097C491.116 3.43097 492.886 4.25964 494.016 5.91698C495.146 7.57431 495.297 9.60831 494.468 12.019L470.625 78.915C469.872 80.949 468.892 82.4556 467.687 83.435C466.482 84.4143 464.862 84.904 462.828 84.904C460.794 84.904 459.174 84.4143 457.969 83.435C456.764 82.4556 455.784 80.9113 455.031 78.802L436.612 26.483L418.08 78.915C417.327 80.949 416.347 82.4556 415.142 83.435C414.012 84.4143 412.43 84.904 410.396 84.904Z'
					stroke='currentColor'
					strokeWidth='2'
				/>
				<path
					d='M525.01 85.13C521.017 85.13 517.439 84.3766 514.275 82.87C511.186 81.288 508.738 79.1786 506.93 76.542C505.197 73.9053 504.331 70.9296 504.331 67.615C504.331 63.547 505.385 60.3453 507.495 58.01C509.604 55.5993 513.032 53.8666 517.778 52.812C522.524 51.7573 528.889 51.23 536.875 51.23H540.83V48.857C540.83 45.0903 540.001 42.3783 538.344 40.721C536.686 39.0636 533.899 38.235 529.982 38.235C527.797 38.235 525.424 38.4986 522.863 39.026C520.377 39.5533 517.74 40.4573 514.953 41.738C513.145 42.5666 511.638 42.6796 510.433 42.077C509.227 41.399 508.399 40.382 507.947 39.026C507.57 37.5946 507.645 36.1633 508.173 34.732C508.775 33.3006 509.943 32.246 511.676 31.568C515.141 30.1366 518.456 29.1196 521.62 28.517C524.859 27.9143 527.797 27.613 530.434 27.613C538.494 27.613 544.483 29.4963 548.401 33.263C552.318 36.9543 554.277 42.7173 554.277 50.552V77.672C554.277 82.4933 552.092 84.904 547.723 84.904C543.278 84.904 541.056 82.4933 541.056 77.672V75.186C539.775 78.2746 537.741 80.723 534.954 82.531C532.166 84.2636 528.852 85.13 525.01 85.13ZM527.948 75.412C531.639 75.412 534.69 74.1313 537.101 71.57C539.587 69.0086 540.83 65.7693 540.83 61.852V59.366H536.988C529.906 59.366 524.972 59.931 522.185 61.061C519.473 62.1156 518.117 64.0743 518.117 66.937C518.117 69.423 518.983 71.457 520.716 73.039C522.448 74.621 524.859 75.412 527.948 75.412ZM541.734 16.991C537.063 16.991 534.728 14.6556 534.728 9.98498C534.728 5.38965 537.063 3.09198 541.734 3.09198C546.329 3.09198 548.627 5.38965 548.627 9.98498C548.627 14.6556 546.329 16.991 541.734 16.991ZM520.377 16.991C518.192 16.991 516.459 16.3883 515.179 15.183C513.973 13.9776 513.371 12.245 513.371 9.98498C513.371 7.80031 513.973 6.10531 515.179 4.89997C516.459 3.69464 518.192 3.09198 520.377 3.09198C525.047 3.09198 527.383 5.38965 527.383 9.98498C527.383 14.6556 525.047 16.991 520.377 16.991Z'
					stroke='currentColor'
					strokeWidth='2'
				/>
				<path
					d='M587.485 85.13C574.904 85.13 568.614 78.0863 568.614 63.999V10.55C568.614 5.80397 570.949 3.43097 575.62 3.43097C580.366 3.43097 582.739 5.80397 582.739 10.55V63.321C582.739 70.101 585.564 73.491 591.214 73.491C591.816 73.491 592.381 73.491 592.909 73.491C593.436 73.4156 593.963 73.3403 594.491 73.265C595.545 73.1143 596.261 73.4156 596.638 74.169C597.014 74.847 597.203 76.2783 597.203 78.463C597.203 80.3463 596.826 81.8153 596.073 82.87C595.319 83.9246 594.076 84.565 592.344 84.791C590.762 85.017 589.142 85.13 587.485 85.13Z'
					stroke='currentColor'
					strokeWidth='2'
				/>
				<path
					d='M625.391 85.13C620.569 85.13 616.313 83.9623 612.622 81.627C609.006 79.2917 606.181 75.977 604.147 71.683C602.113 67.3136 601.096 62.191 601.096 56.315C601.096 50.3637 602.113 45.2786 604.147 41.06C606.181 36.766 609.006 33.4513 612.622 31.116C616.313 28.7806 620.569 27.613 625.391 27.613C629.308 27.613 632.849 28.4793 636.013 30.212C639.177 31.9446 641.55 34.2423 643.132 37.105V10.55C643.132 5.80397 645.467 3.43097 650.138 3.43097C654.884 3.43097 657.257 5.80397 657.257 10.55V77.672C657.257 82.4933 654.921 84.904 650.251 84.904C645.58 84.904 643.245 82.4933 643.245 77.672V75.299C641.663 78.3123 639.29 80.723 636.126 82.531C632.962 84.2636 629.383 85.13 625.391 85.13ZM629.346 74.395C633.564 74.395 636.954 72.8883 639.516 69.875C642.077 66.7863 643.358 62.2663 643.358 56.315C643.358 50.2883 642.077 45.806 639.516 42.868C636.954 39.8546 633.564 38.348 629.346 38.348C625.127 38.348 621.737 39.8546 619.176 42.868C616.614 45.806 615.334 50.2883 615.334 56.315C615.334 62.2663 616.614 66.7863 619.176 69.875C621.737 72.8883 625.127 74.395 629.346 74.395Z'
					stroke='currentColor'
					strokeWidth='2'
				/>
				<path
					d='M699.136 85.13C692.883 85.13 687.497 83.9623 682.977 81.627C678.457 79.2917 674.954 75.977 672.468 71.683C670.057 67.389 668.852 62.304 668.852 56.428C668.852 50.7026 670.019 45.693 672.355 41.399C674.765 37.105 678.042 33.7526 682.186 31.342C686.404 28.856 691.188 27.613 696.537 27.613C704.371 27.613 710.549 30.099 715.069 35.071C719.664 40.043 721.962 46.823 721.962 55.411C721.962 58.1983 720.154 59.592 716.538 59.592H682.412C683.466 69.4607 689.116 74.395 699.362 74.395C701.32 74.395 703.505 74.169 705.916 73.717C708.402 73.1896 710.737 72.3233 712.922 71.118C714.88 69.988 716.538 69.649 717.894 70.101C719.25 70.4776 720.191 71.2687 720.719 72.474C721.321 73.6793 721.397 75.0353 720.945 76.542C720.568 77.9733 719.513 79.2163 717.781 80.271C715.144 81.9283 712.093 83.1713 708.628 84C705.238 84.7533 702.074 85.13 699.136 85.13ZM697.102 37.218C692.958 37.218 689.606 38.4986 687.045 41.06C684.483 43.6213 682.939 47.0866 682.412 51.456H710.21C709.908 46.8606 708.628 43.3576 706.368 40.947C704.183 38.461 701.094 37.218 697.102 37.218Z'
					stroke='currentColor'
					strokeWidth='2'
				/>
			</svg>
		</div>
	);
};
