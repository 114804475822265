import { FC } from 'react';
import versionControl from '../../../../assets/undraw/version-control';
import { Container } from '../../../layout/container';
import { AnimatedName } from './animated-name';
import { AnimatedText } from './animated-text';
import { BackgroundTexts } from './background-texts';
import { CtaButton } from './cta-button';
import { ScrollHint } from './scroll-hint';

type Props = {
	age: number;
};
export const HeroSection: FC<Props> = (props) => {
	return (
		<div className='relative'>
			<div className='relative w-full h-screen bg-background dark:bg-background-dark'>
				<BackgroundTexts />
				<ScrollHint />

				<div className='w-full h-full flex items-center overflow-hidden'>
					<Container>
						<div className='grid grid-cols-1 md:grid-cols-2 relative'>
							<div className='flex flex-col justify-center w-full md:w-10/12 z-30'>
								<h1 className='sr-only'>Tobias Wälde</h1>
								<AnimatedName />
								<AnimatedText age={props.age} />
								<CtaButton />
							</div>
							<div className='absolute md:relative z-20'>
								<div className='opacity-20 md:opacity-100'>
									<div className='overflow-visible'>{versionControl}</div>
								</div>
							</div>
						</div>
					</Container>
				</div>
			</div>
		</div>
	);
};
