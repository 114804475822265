import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import classes from './index.module.scss';

type Props = {
	pattern: string;
	patternSize?: number;
	fadeIn?: boolean;
	fadeOut?: boolean;
	className?: string;
	patternClassName?: string;
};
export const BackgroundPatternWrapper: FC<PropsWithChildren<Props>> = (props) => {
	const { fadeIn, fadeOut } = props;

	return (
		<div className={clsx('relative', props.className)}>
			<div
				className={clsx(
					'absolute inset-0',
					{
						[classes.maskStart]: fadeIn,
						[classes.maskEnd]: fadeOut,
						[classes.maskStartEnd]: fadeIn && fadeOut,
					},
					props.patternClassName
				)}
				style={{
					backgroundImage: `url("${props.pattern}")`,
					backgroundSize: props.patternSize,
				}}
			/>

			<div className='relative'>{props.children}</div>
		</div>
	);
};
