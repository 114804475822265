import { useTheme } from 'next-themes';
import { FC } from 'react';
import { Divider } from '../../../ui/divider';

export const DividerTop: FC = () => {
	const isDark = useTheme().resolvedTheme === 'dark';

	const color = isDark ? '#222831' : '#f6f7fb';
	const shadowMatrix = isDark
		? undefined //'0 0 0 0 0.968627 0 0 0 0 0.466667 0 0 0 0 0.211765 0 0 0 0.745 0'
		: '0 0 0 0 0.784314 0 0 0 0 0.784314 0 0 0 0 0.784314 0 0 0 0.745 0';

	const divider = (
		<svg
			viewBox='0 0 1440 294'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className='w-full'
			preserveAspectRatio='none'
		>
			<g filter='url(#filter0_d_10_9)'>
				<path
					d='M0 224L60 213.3C120 203 240 181 360 149.3C480 117 600 75 720 85.3C840 96 960 160 1080 181.3C1200 203 1320 181 1380 170.7L1440 160V0H1380C1320 0 1200 0 1080 0C960 0 840 0 720 0C600 0 480 0 360 0C240 0 120 0 60 0H0V224Z'
					fill='url(#paint0_linear_10_9)'
				/>
			</g>
			<defs>
				<filter
					id='filter0_d_10_9'
					x='-70'
					y='-70'
					width='1580'
					height='364'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset />
					<feGaussianBlur stdDeviation='35' />
					<feColorMatrix type='matrix' values={shadowMatrix} />
					<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_10_9' />
					<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_10_9' result='shape' />
				</filter>
				<linearGradient
					id='paint0_linear_10_9'
					x1='9'
					y1='1.3124e-08'
					x2='1440'
					y2='1.11825e-06'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor={color} />
					<stop offset='1' stopColor={color} />
				</linearGradient>
			</defs>
		</svg>
	);
	return <Divider position='top' divider={divider} />;
};
