import Link from 'next/link';
import { FC, useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

type Props = {
	value: number;
	caption: string;
	prefix?: string;
	suffix?: string;
	decimals?: number;
	className?: string;
	href?: string;
	onClick?: () => void;
};

export const FactItem: FC<Props> = (props) => {
	const [value, setValue] = useState<number>(0);
	const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

	const handleVisibilityChange = (isVisible: boolean) => {
		if (isVisible) {
			setValue(props.value);

			if (timer) {
				clearTimeout(timer);
				setTimer(undefined);
			}
		} else {
			if (value !== 0) {
				const t = setTimeout(() => setValue(0), 10000);
				setTimer(t);
			}
		}
	};

	return (
		<div className='fact-item'>
			<Link
				href={props.href!}
				className='item'
				onClick={(e) => {
					if (props.onClick) {
						e.preventDefault();
						props.onClick();
					}
				}}
			>
				<div className='content'>
					<span className='value font-bold'>
						{/* <VisibilitySensor onChange={handleVisibilityChange}>
							<CountUp
								end={value}
								duration={1.5}
								prefix={props.prefix || ''}
								suffix={props.suffix || ''}
								decimals={props.decimals || 0}
							/>
						</VisibilitySensor> */}
						{props.value}
					</span>
					<span className='caption'>{props.caption}</span>
				</div>
			</Link>
		</div>
	);
};
FactItem.defaultProps = {
	href: '/',
};
