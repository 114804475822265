import qs from 'qs';
import { api } from '../services/http';
import { Course } from '../types/collections/course';
import { StrapiQuery, StrapiResponse } from '../types/strapi';
import { strapiResponseToArray } from '../util/strapi';

export async function getCourses(query: StrapiQuery = {}): Promise<Course[]> {
	const q = qs.stringify(query, { encodeValuesOnly: true });
	try {
		const res = await api.get<StrapiResponse<Course[]>>(`/api/courses?${q}`);
		return strapiResponseToArray(res.data);
	} catch (err) {
		return [];
	}
}

export async function getCoursesCompleteInfo(): Promise<{
	count: number;
	completeDuration: number;
}> {
	try {
		const courses = await getCourses({ pagination: { page: 1, pageSize: 1000 } });

		const completeDuration = courses
			.map((x) => x.duration)
			.reduce((prev, value) => prev + value, 0);

		return {
			count: courses.length,
			completeDuration,
		};
	} catch (err) {
		return {
			count: 0,
			completeDuration: 0,
		};
	}
}
