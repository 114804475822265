import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { icons } from '../../../../assets/icons';
import { Project } from '../../../../types/collections/project';
import { Container } from '../../../layout/container';
import { AnchorButton } from '../../../ui/button/anchor-button';
import { Heading } from '../../../ui/heading';
import { FeaturedProjectsGrid } from './featured-projects-grid';

type Props = {
	featuredProjects: Project[];
};
export const PortfolioSection: FC<Props> = ({ featuredProjects }) => {
	const { t } = useTranslation('index');
	return (
		<Container className='py-24 flex flex-col gap-10 w-full'>
			<Heading component='h3' type='h2' header={t('projects.title') || ''} />

			<div>
				<FeaturedProjectsGrid projects={featuredProjects} />
			</div>

			<div className='flex flex-row justify-center'>
				<AnchorButton href={'/projects'} variant='contained' icon={icons.layoutGrid}>
					{t('projects.view-all')}
				</AnchorButton>
			</div>
		</Container>
	);
};
