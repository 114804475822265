import { useTheme } from 'next-themes';
import { FC } from 'react';
import colors from '../../../../config/colors';
import { Divider } from '../../../ui/divider';

export const DividerTop: FC = () => {
	const isDark = useTheme().resolvedTheme === 'dark';

	const leftColor = isDark ? colors.dark.primary : colors.light.primary;
	const rightColor = isDark ? colors.dark.secondary : colors.light.secondary;
	const shadowMatrix = isDark
		? undefined
		: '0 0 0 0 0.443137 0 0 0 0 0.407843 0 0 0 0 0.803922 0 0 0 0.745 0';

	const divider = (
		<svg
			viewBox='0 0 1440 262'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className='w-full'
			preserveAspectRatio='none'
		>
			<g filter='url(#filter0_d_8_5)'>
				<path
					d='M0 70L48 96.7C96 123 192 177 288 203.3C384 230 480 230 576 198C672 166 768 102 864 102C960 102 1056 166 1152 192.7C1248 219 1344 209 1392 203.3L1440 198V262H1392C1344 262 1248 262 1152 262C1056 262 960 262 864 262C768 262 672 262 576 262C480 262 384 262 288 262C192 262 96 262 48 262H0V70Z'
					fill='url(#paint0_linear_8_5)'
				/>
			</g>
			<defs>
				<filter
					id='filter0_d_8_5'
					x='-70'
					y='0'
					width='1580'
					height='332'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset />
					<feGaussianBlur stdDeviation='35' />
					<feColorMatrix type='matrix' values={shadowMatrix} />
					<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_8_5' />
					<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_8_5' result='shape' />
				</filter>
				<linearGradient
					id='paint0_linear_8_5'
					x1='4.92118e-06'
					y1='262'
					x2='1440'
					y2='262'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor={leftColor} />
					<stop offset='1' stopColor={rightColor} />
				</linearGradient>
			</defs>
		</svg>
	);

	return <Divider className='bg-main-bg dark:bg-main-bg-dark ' divider={divider} position='top' />;
};
