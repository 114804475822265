import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { icons } from '../../../../assets/icons';
import { AnchorButton } from '../../../ui/button/anchor-button';

export const CtaButton: FC = () => {
	const { t } = useTranslation('index');
	return (
		<div className='flex justify-center md:justify-start ml-4'>
			<AnchorButton variant='contained' href='/#contact' icon={icons.message}>
				{t('hero.cta-button.text')}
			</AnchorButton>
		</div>
	);
};
