import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import { Modal } from '.';
import { icons } from '../../../assets/icons';
import { IconButton } from '../button/icon-button';
import { Card } from '../card';

type Props = {
	open: boolean;
	onClose: () => void;
	className?: string;
};
export const CardModal: FC<PropsWithChildren<Props>> = (props) => {
	const closeButton = (
		<div className='absolute top-4 right-4'>
			<IconButton
				icon={icons.x}
				variant='flat'
				className={clsx(
					'trasition-colors duration-200',
					'hover:bg-background dark:hover:bg-background-dark',
					'focus:bg-background dark:focus:bg-background-dark'
				)}
				noActive
				noFocus
				noHover
				onClick={() => props.onClose()}
			/>
		</div>
	);

	return (
		<Modal open={props.open} onClose={props.onClose}>
			<Card
				className={clsx(
					'bg-main-bg dark:bg-main-bg-dark relative w-full max-w-2xl shadow-xl',
					props.className
				)}
				onClick={(e) => e.stopPropagation()}
			>
				{closeButton}
				{props.children}
			</Card>
		</Modal>
	);
};
