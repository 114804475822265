import clsx from 'clsx';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { bounce } from '../../../../animations/bounce';
import { isMobile } from 'react-device-detect';
import { icons } from '../../../../assets/icons';
import { useTranslation } from 'next-i18next';

export const ScrollHint: FC = () => {
	const icon = isMobile ? icons.chevronUp : icons.mouse;

	const { t } = useTranslation('index');
	return (
		<div
			className={clsx(
				'absolute w-full flex flex-col items-center ',
				'bottom-16 md:bottom-12 lg:bottom-8 xl:bottom-4',
				'text-typography dark:text-typography-dark dark:opacity-60'
			)}
		>
			<motion.div transition={bounce} animate={{ y: ['0px', '5px'] }} className='mb-2'>
				<div className='w-6 h-6 md:w-8 md:h-8'>{icon}</div>
			</motion.div>
			<p className=''>{isMobile ? t('hero.scroll-hint.mobile') : t('hero.scroll-hint.desktop')}</p>
		</div>
	);
};
