import clsx from 'clsx';
import { FC, useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export interface StatItemProps {
	value: number;
	caption: string;
	prefix?: string;
	suffix?: string;
	decimals?: number;
	className?: string;
	onClick?: () => void;
}
export const StatItem: FC<StatItemProps> = (props) => {
	const [value, setValue] = useState(0);

	return (
		<div className='w-28 h-28 sm:w-32 sm:h-32 md:w-40 md:h-40 mx-auto'>
			<div
				className={clsx(
					'w-28 h-28 sm:w-32 sm:h-32 md:w-40 md:h-40',
					'mx-auto rounded-full',
					'flex flex-col justify-center border-4 absolute z-20',
					'transition-all duration-200',
					props.className
				)}
				onClick={props.onClick}
			>
				<div className='flex flex-col text-inherit text-center'>
					{/* <VisibilitySensor
						onChange={(isVisible: boolean) => setValue(isVisible ? props.value : 0)}
					>
						<h5 className='text-xl sm:text-2xl md:text-4xl font-medium py-0'>
							<CountUp
								end={value}
								duration={1.5}
								prefix={props.prefix || ''}
								suffix={props.suffix || ''}
								decimals={props.decimals ?? 0}
							/>
						</h5>
					</VisibilitySensor> */}
					<h5 className='text-xl sm:text-2xl md:text-4xl font-medium py-0'>{props.value}</h5>
					<span className='text-md sm:text-xl md:text-2xl uppercase py-0'>{props.caption}</span>
				</div>
			</div>
		</div>
	);
};
