import { FC } from 'react';

type Props = {
	text: string;
};
export const TestimonialText: FC<Props> = (props) => {
	const { text } = props;
	return (
		<div className='flex-1'>
			<p className='text-typography dark:text-typography-dark'>{text}</p>
		</div>
	);
};
