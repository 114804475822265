import { FC } from 'react';

type Props = {
	primary?: string;
	secondary?: string;
	background?: string;
	borderDark?: string;
};

export const AppWireframe: FC<Props> = (props) => {
	const primary = props.primary || '#6c63ff';
	const secondary = props.secondary || '#3f3d56';
	const background = props.background || '#fff';
	const borderDark = props.borderDark || '#e6e6e6';

	return (
		<svg
			id='a5b39606-6275-4e50-bae6-002d29ca6512'
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			width='768'
			height='728.85799'
			viewBox='0 0 768 728.85799'
		>
			<path
				d='M682.88391,258.51823h-3.99878V148.9729A63.40187,63.40187,0,0,0,615.48334,85.571H383.39661a63.40184,63.40184,0,0,0-63.402,63.40171V749.94712A63.40181,63.40181,0,0,0,383.39642,813.349H615.483a63.40185,63.40185,0,0,0,63.402-63.40167V336.49449h3.99884Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<path
				d='M618.04138,102.066h-30.295a22.49486,22.49486,0,0,1-20.82715,30.99052H433.95978A22.4948,22.4948,0,0,1,413.13263,102.066H384.837a47.34781,47.34781,0,0,0-47.34784,47.34774V749.50627A47.34781,47.34781,0,0,0,384.837,796.85411h233.2044a47.34781,47.34781,0,0,0,47.34784-47.34778h0V149.41371a47.34777,47.34777,0,0,0-47.34784-47.3477Z'
				transform='translate(-216 -85.571)'
				fill={background}
			/>
			<path
				d='M983,814.429H217a1,1,0,0,1,0-2H983a1,1,0,0,1,0,2Z'
				transform='translate(-216 -85.571)'
				fill={secondary}
			/>
			<path
				d='M642.82017,268.07475H359.94623a5.24456,5.24456,0,0,1-5.2384-5.2384V192.6674a5.24456,5.24456,0,0,1,5.2384-5.2384H642.82017a5.24458,5.24458,0,0,1,5.23844,5.2384v70.169A5.24458,5.24458,0,0,1,642.82017,268.07475ZM359.94623,189.52433a3.14646,3.14646,0,0,0-3.14307,3.14307v70.169a3.14646,3.14646,0,0,0,3.14307,3.14307H642.82017a3.14644,3.14644,0,0,0,3.14307-3.14307V192.6674a3.14644,3.14644,0,0,0-3.14307-3.14307Z'
				transform='translate(-216 -85.571)'
				fill={secondary}
			/>
			<path
				d='M642.82017,545.70982H359.94623a5.24459,5.24459,0,0,1-5.2384-5.23837V410.58471a5.24456,5.24456,0,0,1,5.2384-5.2384H642.82017a5.2446,5.2446,0,0,1,5.23838,5.2384V540.47145A5.2446,5.2446,0,0,1,642.82017,545.70982ZM359.94623,407.44167a3.14647,3.14647,0,0,0-3.14307,3.14307V540.47145a3.14645,3.14645,0,0,0,3.14307,3.14307H642.82017a3.14643,3.14643,0,0,0,3.14307-3.14307V410.58471a3.14645,3.14645,0,0,0-3.14307-3.14307Z'
				transform='translate(-216 -85.571)'
				fill={secondary}
			/>
			<path
				d='M640.72481,377.0334h-125.722a5.24456,5.24456,0,0,1-5.2384-5.2384V301.62606a5.24457,5.24457,0,0,1,5.2384-5.2384h125.722a5.24461,5.24461,0,0,1,5.23838,5.2384v70.169A5.24458,5.24458,0,0,1,640.72481,377.0334ZM515.00277,298.483a3.14648,3.14648,0,0,0-3.14307,3.14307V371.795a3.14648,3.14648,0,0,0,3.14307,3.14307h125.722a3.14645,3.14645,0,0,0,3.14307-3.14307v-70.169a3.14645,3.14645,0,0,0-3.14307-3.14307Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<path
				d='M485.66827,715.43389h-125.722a5.24456,5.24456,0,0,1-5.2384-5.23843V579.26116a5.24459,5.24459,0,0,1,5.2384-5.23837h125.722a5.24459,5.24459,0,0,1,5.2384,5.23837V710.19552A5.24459,5.24459,0,0,1,485.66827,715.43389Zm-125.722-139.3158a3.14645,3.14645,0,0,0-3.14307,3.14307V710.19552a3.14645,3.14645,0,0,0,3.14307,3.14307h125.722a3.14646,3.14646,0,0,0,3.14307-3.14307V579.26116a3.14646,3.14646,0,0,0-3.14307-3.14307Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<path
				d='M640.72481,715.43389h-125.722a5.24457,5.24457,0,0,1-5.2384-5.23843V579.26116a5.24459,5.24459,0,0,1,5.2384-5.23837h125.722a5.2446,5.2446,0,0,1,5.23838,5.23837V710.19552A5.2446,5.2446,0,0,1,640.72481,715.43389Zm-125.722-139.3158a3.14646,3.14646,0,0,0-3.14307,3.14307V710.19552a3.14646,3.14646,0,0,0,3.14307,3.14307h125.722a3.14643,3.14643,0,0,0,3.14307-3.14307V579.26116a3.14643,3.14643,0,0,0-3.14307-3.14307Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<circle cx='185.80372' cy='365.78724' r='21.33847' fill={primary} />
			<path
				d='M451.08539,437.1326a3.55641,3.55641,0,0,0,0,7.11282H618.74476a3.55642,3.55642,0,0,0,0-7.11282Z'
				transform='translate(-216 -85.571)'
				fill={primary}
			/>
			<path
				d='M451.08539,458.471a3.55641,3.55641,0,0,0-.015,7.11281h72.15934a3.55641,3.55641,0,1,0,0-7.11281Z'
				transform='translate(-216 -85.571)'
				fill={primary}
			/>
			<path
				d='M385.43655,492.58508c-2.70528,0-4.90674,1.59559-4.90674,3.55643s2.20146,3.55639,4.90674,3.55639H616.75487c2.70532,0,4.90675-1.59561,4.90675-3.55639s-2.20149-3.55643-4.90675-3.55643Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<path
				d='M385.43655,513.92353c-2.70528,0-4.90674,1.59555-4.90674,3.55639s2.20146,3.5564,4.90674,3.5564H616.75487c2.70532,0,4.90675-1.59562,4.90675-3.5564s-2.20149-3.55639-4.90675-3.55639Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<circle cx='187.89908' cy='139.48847' r='21.33847' fill={primary} />
			<path
				d='M453.18075,210.83383a3.55642,3.55642,0,0,0,0,7.11282H620.84012a3.55642,3.55642,0,1,0,.015-7.11282c-.005,0-.01,0-.015,0Z'
				transform='translate(-216 -85.571)'
				fill={primary}
			/>
			<path
				d='M453.18075,232.17229a3.55642,3.55642,0,0,0,0,7.11282h72.14433a3.55642,3.55642,0,1,0,.015-7.11282q-.0075,0-.015,0Z'
				transform='translate(-216 -85.571)'
				fill={primary}
			/>
			<path
				d='M485.557,377.04619H367.61883l27.75477-48.07353a5.66762,5.66762,0,0,1,9.81687,0l11.85647,20.53614,20.16014-34.91816a7.09595,7.09595,0,0,1,12.29078,0Zm-114.30914-2.09536H481.928l-34.245-59.31257a4.99991,4.99991,0,0,0-8.66075,0L417.047,353.6995l-13.67144-23.67916a3.57157,3.57157,0,0,0-6.18684,0Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<path
				d='M636.42287,377.04619H518.48472l27.75476-48.07353a5.66759,5.66759,0,0,1,9.8169,0l11.8565,20.53614L588.073,314.59064a7.096,7.096,0,0,1,12.29079,0Zm-114.30908-2.09536h110.68l-34.245-59.31257a4.99993,4.99993,0,0,0-8.66073,0l-21.9752,38.06124-13.6715-23.67916a3.57158,3.57158,0,0,0-6.18684,0Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<path
				d='M485.66827,377.0334h-125.722a5.24456,5.24456,0,0,1-5.2384-5.2384V301.62606a5.24456,5.24456,0,0,1,5.2384-5.2384h125.722a5.24457,5.24457,0,0,1,5.2384,5.2384v70.169A5.24456,5.24456,0,0,1,485.66827,377.0334ZM359.94623,298.483a3.14647,3.14647,0,0,0-3.14307,3.14307V371.795a3.14647,3.14647,0,0,0,3.14307,3.14307h125.722a3.14648,3.14648,0,0,0,3.14307-3.14307v-70.169a3.14648,3.14648,0,0,0-3.14307-3.14307Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<path
				d='M386.73507,601.0238a3.55643,3.55643,0,1,0,0,7.11285H458.8794a3.55642,3.55642,0,0,0,0-7.11285Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<path
				d='M386.73507,621.97734a3.55643,3.55643,0,1,0,0,7.11285H458.8794a3.55643,3.55643,0,0,0,0-7.11285Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<path
				d='M422.80749,688.43282a22.386,22.386,0,1,1,22.386-22.38595h0A22.41132,22.41132,0,0,1,422.80749,688.43282Zm0-42.67659a20.29062,20.29062,0,1,0,20.29061,20.29058h0a20.3142,20.3142,0,0,0-20.29061-20.29053Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<path
				d='M613.93594,688.43282a3.55643,3.55643,0,1,0,0-7.11285H541.79161a3.55642,3.55642,0,0,0,0,7.11285Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<path
				d='M613.93594,667.47928a3.55643,3.55643,0,1,0,0-7.11285H541.79161a3.55642,3.55642,0,0,0,0,7.11285Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<path
				d='M577.86352,601.0238a22.386,22.386,0,1,1-22.38594,22.386v-.00006A22.41135,22.41135,0,0,1,577.86352,601.0238Zm0,42.67659a20.29062,20.29062,0,1,0-20.29052-20.2907v.00006a20.31422,20.31422,0,0,0,20.29052,20.29058Z'
				transform='translate(-216 -85.571)'
				fill={borderDark}
			/>
			<polygon
				points='589.987 713.726 573.8 713.726 566.1 651.292 589.99 651.293 589.987 713.726'
				fill='#ffb8b8'
			/>
			<path
				d='M557.47022,709.10053h35.985a0,0,0,0,1,0,0v19.655a0,0,0,0,1,0,0H542.58336a0,0,0,0,1,0,0v-4.76818A14.88686,14.88686,0,0,1,557.47022,709.10053Z'
				fill='#2f2e41'
			/>
			<polygon
				points='654.681 713.726 638.495 713.726 630.794 651.292 654.684 651.293 654.681 713.726'
				fill='#ffb8b8'
			/>
			<path
				d='M622.16457,709.10053h35.985a0,0,0,0,1,0,0v19.655a0,0,0,0,1,0,0H607.27772a0,0,0,0,1,0,0v-4.76818A14.88686,14.88686,0,0,1,622.16457,709.10053Z'
				fill='#2f2e41'
			/>
			<path
				d='M777.78618,619.314a12.38035,12.38035,0,0,0-1.067-18.95377l6.9347-27.42815-15.64018-8.27949L758.76453,603.524a12.44744,12.44744,0,0,0,19.02165,15.79Z'
				transform='translate(-216 -85.571)'
				fill='#ffb8b8'
			/>
			<polygon
				points='660.438 485.836 659.118 578.257 657.797 699.724 628.751 697.083 614.228 529.406 595.744 697.083 565.756 697.083 562.736 528.086 573.299 491.117 660.438 485.836'
				fill='#2f2e41'
			/>
			<path
				d='M786.40347,577.25224l-.425-.19151,16.55373-113.22725-8.41567-34.2233a7.48958,7.48958,0,0,1,4.64191-8.79869l15.14185-5.67771,5.34936-14.7114,42.2064,4.548,1.03791,11.49738c4.57364,1.46685,42.23692,14.01,39.63821,28.30213-2.56487,14.10666-17.70914,58.48857-18.47821,60.73779l-2.65218,63.64913-.32623.16312c-.39269.19634-29.48413,14.50056-61.08849,14.49814C808.45759,583.81743,797.01005,582.04247,786.40347,577.25224Z'
				transform='translate(-216 -85.571)'
				fill={secondary}
			/>
			<path
				d='M758.21733,589.13748l13.30648-85.16194c.07914-.50144,7.95229-50.355,10.56308-66.01978,2.69567-16.17282,15.21556-16.3601,15.34183-16.3601h6.88961l8.05861,36.26529-15.87558,72.76319-14.64706,60.298Z'
				transform='translate(-216 -85.571)'
				fill={secondary}
			/>
			<path
				d='M844.529,580.73224a12.38035,12.38035,0,0,1,15.11513-11.48551l16.372-23.07271,16.44782,6.52949-23.57811,32.25841a12.44744,12.44744,0,0,1-24.35687-4.22968Z'
				transform='translate(-216 -85.571)'
				fill='#ffb8b8'
			/>
			<path
				d='M862.48882,560.88972l29.109-48.95706-11.85957-31.6255,16.15228-43.074.59568.25555c.39058.16735,9.56837,4.26281,10.92859,18.92951l18.56189,58.33693L876.4375,579.02237Z'
				transform='translate(-216 -85.571)'
				fill={secondary}
			/>
			<circle
				cx='838.69886'
				cy='362.75321'
				r='30.38881'
				transform='translate(-97.89087 839.10401) rotate(-61.3368)'
				fill='#ffb8b8'
			/>
			<path
				d='M818.29606,347.95144c3.14573,3.72279,8.46951,4.77329,13.38045,5.204,9.97353.87471,24.47619-.54414,34.223-2.79449.6956,6.74242-1.20105,14.01237,2.77028,19.55871a90.798,90.798,0,0,0,3.22611-20.58362c.12039-2.98589.07326-6.07478-1.14028-8.81587s-3.85957-5.06786-6.907-5.09221a17.311,17.311,0,0,1,7.7218-3.30125l-9.64913-4.849,2.47789-2.54393-17.46919-1.069,5.05971-3.21437a109.71467,109.71467,0,0,0-22.98555-.72558c-3.55989.26226-7.28177.7633-10.16613,2.82589s-4.551,6.162-2.7604,9.1903a13.34408,13.34408,0,0,0-10.16942,8.069c-1.28587,3.26-1.168,6.88092-.77194,10.3546a73.39889,73.39889,0,0,0,3.84846,16.281'
				transform='translate(-216 -85.571)'
				fill='#2f2e41'
			/>
		</svg>
	);
};
