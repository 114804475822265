import { createRef, FC, MouseEventHandler, PropsWithChildren } from 'react';
import { BorderEffectGridItem } from './item';
import clsx from 'clsx';
import classes from './index.module.scss';

type Props = {
	className?: string;
};
const BorderEffectGrid: FC<PropsWithChildren<Props>> = (props) => {
	const wrapper = createRef<HTMLDivElement>();

	const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
		if (!wrapper.current) return;
		const cards = wrapper.current.querySelectorAll('.card');
		cards?.forEach((card) => {
			const rect = card.getBoundingClientRect();

			const x = e.clientX - rect.left;
			const y = e.clientY - rect.top;
			(card as HTMLDivElement).style.setProperty('--xPos', `${x}px`);
			(card as HTMLDivElement).style.setProperty('--yPos', `${y}px`);
		});
	};

	return (
		<div
			ref={wrapper}
			onMouseMove={handleMouseMove}
			className={clsx(classes.cards, props.className)}
		>
			{props.children}
		</div>
	);
};

export default Object.assign(BorderEffectGrid, {
	Item: BorderEffectGridItem,
});
