import { FC } from 'react';
import { Testimonial } from '../../../../../types/collections/testimonial';
import { TestimonialAvatar } from './avatar';
import { TestimonialClientInfo } from './client-info';
import { TestimonialRating } from './rating';
import { TestimonialText } from './text';

type Props = {
	testimonial: Testimonial;
};
export const TestimonialCard: FC<Props> = ({ testimonial }) => {
	return (
		<div className='flex flex-col gap-3 p-6 select-none'>
			<div className='flex flex-row gap-4'>
				<TestimonialAvatar name={testimonial.name} icon={testimonial.icon} />
				<TestimonialClientInfo name={testimonial.name} website={testimonial.website} />
				<TestimonialRating value={testimonial.rating} />
			</div>
			<TestimonialText text={testimonial.text} />
		</div>
	);
};
